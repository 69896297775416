@font-face {
  font-family: Montserrat;
  src: url('./fonts/Montserrat-Thin.ttf') format('truetype');
  font-weight: 100;
}
@font-face {
  font-family: Montserrat;
  src: url('./fonts/Montserrat-ExtraLight.ttf') format('truetype');
  font-weight: 200;
}
@font-face {
  font-family: Montserrat;
  src: url('./fonts/Montserrat-Light.ttf') format('truetype');
  font-weight: 300;
}

@font-face {
  font-family: Montserrat;
  src: url('./fonts/Montserrat-Regular.ttf') format('truetype');
}

@font-face {
  font-family: Montserrat;
  src: url('./fonts/Montserrat-Medium.ttf') format('truetype');
  font-weight: 500;
}

@font-face {
  font-family: Montserrat;
  src: url('./fonts/Montserrat-SemiBold.ttf') format('truetype');
  font-weight: 600;
}

@font-face {
  font-family: Montserrat;
  src: url('./fonts/Montserrat-Bold.ttf') format('truetype');
  font-weight: bold;
}

@font-face {
  font-family: Montserrat;
  src: url('./fonts/Montserrat-ExtraBold.ttf') format('truetype');
  font-weight: 800;
}

@font-face {
  font-family: Montserrat;
  src: url('./fonts/Montserrat-Black.ttf') format('truetype');
  font-weight: 900;
}

#root * {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
  box-sizing: border-box;
}

body .fa,
body .far,
body .fas {
  font-family: 'Font Awesome 5 Free';
  font-weight: 600;
}

#root h1 {
  font-size: 3.714rem;
  letter-spacing: 0.014rem;
  line-height: 4.571rem;
  font-weight: 300;
}
#root h2 {
  font-size: 3.143rem;
  letter-spacing: 0rem;
  line-height: 3.857rem;
  font-weight: 500;
}
#root h3 {
  font-size: 2.286rem;
  letter-spacing: 0.007rem;
  line-height: 2.857rem;
  font-weight: 600;
}
#root h4 {
  font-size: 1.857rem;
  letter-spacing: 0.014rem;
  line-height: 2.286rem;
  font-weight: 500;
}
#root h5 {
  font-size: 1.429rem;
  letter-spacing: 0.014rem;
  line-height: 1.857rem;
  font-weight: 600;
}
#root h6 {
  font-size: 1.286rem;
  letter-spacing: 0.014rem;
  line-height: 1.714rem;
  font-weight: 500;
}

#root .subtitle--normal-font {
  font-size: 1.143rem;
  letter-spacing: 0.007rem;
  line-height: 1.714rem;
  font-weight: 500;
}
#root .subtitle--small-font {
  font-size: 1rem;
  letter-spacing: 0.007rem;
  line-height: 1.286rem;
  font-weight: 500;
}

#root .body--normal-font {
  font-size: 1.143rem;
  letter-spacing: 0.007rem;
  line-height: 1.714rem;
  font-weight: normal;
}
#root .body--small-font {
  font-size: 1rem;
  letter-spacing: 0.007rem;
  line-height: 1.571rem;
  font-weight: normal;
}

/*#root button {*/
/*  font-size: 1rem;*/
/*  letter-spacing: 0.014rem;*/
/*  line-height: 1.286rem;*/
/*  font-weight: 600;*/
/*}*/
