#root i.icon {
  font-family: Icons, 'Font Awesome 5 Free';
}

body i.icon {
  font-family: Icons, 'Font Awesome 5 Free';
}

i.icon {
  height: auto;
}

#root {
  background-color: var(--jbmp-primary-color-10);
}

#root .ui.toggle.checkbox input:checked ~ .box:before,
.ui.toggle.checkbox input:checked ~ label:before,
.ui.toggle.checkbox input:focus:checked ~ .box:before,
.ui.toggle.checkbox input:focus:checked ~ label:before {
  background-color: var(--jbmp-primary-color) !important;
}

#root .ui.toggle.checkbox .box:before,
.ui.toggle.checkbox label:before {
  background-color: var(--jbmp-primary-color-10);
}

/* calendar */
.react-datepicker__day--selected,
.react-datepicker__day--selected:enabled:hover,
.react-datepicker__day--selected:enabled:focus {
  background-color: var(--jbmp-primary-color) !important;
}
.react-datepicker__day {
  border-radius: 4.2px;
}

.react-datepicker__day--today.react-datepicker__day--selected {
  background-color: var(--jbmp-primary-color) !important;
}

.react-datepicker * {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
  text-transform: capitalize;
  letter-spacing: 0.07rem;
}

.react-datepicker__header {
  background-color: var(--jbmp-primary-color) !important;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  color: var(--jbmp-white-color) !important;
}

.react-datepicker__navigation button:enabled:hover,
.react-datepicker__navigation button:enabled:focus,
.react-datepicker__day--keyboard-selected:hover,
.react-datepicker__month-text--keyboard-selected:hover,
.react-datepicker__quarter-text--keyboard-selected:hover,
.react-datepicker__year-text--keyboard-selected:hover {
  background-color: var(--jbmp-primary-color-10) !important;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  background-color: var(--jbmp-white-color) !important;
  color: var(--jbmp-primary-color);
}

.react-datepicker__day-names .react-datepicker__day-name,
.react-datepicker__day-names .react-datepicker__day,
.react-datepicker__day-names .react-datepicker__time-name {
  color: var(--jbmp-white-color);
}

/* calendar */
.react-calendar__tile--active,
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background-color: var(--jbmp-primary-color) !important;
}

.react-calendar__tile--now {
  background-color: var(--jbmp-blue-40) !important;
}

.react-calendar * {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
  text-transform: capitalize;
}

.react-calendar__tile--hasActive {
  background-color: var(--jbmp-primary-color) !important;
  color: var(--jbmp-white-color);
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: var(--jbmp-primary-color-10) !important;
}

/* semantic table */
.ui.sortable.table thead th.sorted {
  background: var(--jbmp-blue-30);
}

.ui.sortable.table thead th:hover,
.ui.sortable.table thead th.sorted:hover {
  background: var(--jbmp-blue-10);
}

.ui.table thead th {
  background: var(--jbmp-blue-20);
  color: white;
}
/* end semantic table */

.ui.dimmer {
  background: rgba(0, 0, 0, 0.45) !important;
}

.ui.checkbox input[disabled]~label:hover:before  {
  background: #fff;
  border-top-color: rgb(212, 212, 213);
  border-right-color: rgb(212, 212, 213);
  border-bottom-color: rgb(212, 212, 213);
  border-left-color: rgb(212, 212, 213);
}