:root {
  --jbmp-primary-color: #25282b;
  --jbmp-primary-color-90: #3b3e40;
  --jbmp-primary-color-80: #515355;
  --jbmp-primary-color-70: #66696b;
  --jbmp-primary-color-60: #7c7e80;
  --jbmp-primary-color-50: #929495;
  --jbmp-primary-color-40: #a8a9aa;
  --jbmp-primary-color-30: #bebfbf;
  --jbmp-primary-color-20: #d3d4d5;
  --jbmp-primary-color-10: #e9eaea;

  --jbmp-white-color: #ffffff;
  --jbmp-blue-10: #e7edf2;
  --jbmp-blue-20: #cfdbe6;
  --jbmp-blue-30: #b7c9d9;
  --jbmp-blue-40: #9fb7cd;
  --jbmp-blue-50: #87a5c0;
  --jbmp-blue-60: #6f94b3;
  --jbmp-blue-70: #5781a7;
  --jbmp-blue-80: #3f709a;
  --jbmp-blue-90: #0f4c81;
  --jbmp-blue: #0f4c81;

  --jbmp-error: #fb4e4e;
  --jbmp-error-light: #ff6262;
  --jbmp-error-dark: #e93c3c;
  --jbmp-warning: #f6a609;
  --jbmp-warning-light: #ffbc1f;
  --jbmp-warning-dark: #e89806;
  --jbmp-success: #2ac769;
  --jbmp-success-light: #40dd7f;
  --jbmp-success-dark: #1ab759;
  --jbmp-info: #3e91f9;

  --jbmp-job-board-draft: #6f94b3;
  --jbmp-job-board-active: #40dd7f;
  --jbmp-job-board-inactive: #ff751f;
}
