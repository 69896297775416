body .ui.primary.button,
body .ui.primary.buttons {
  background-color: var(--jbmp-primary-color-80);
}

body .ui.primary.button:hover,
body .ui.primary.buttons:hover {
  background-color: var(--jbmp-primary-color);
}

body .ui.primary.button:active,
body .ui.primary.buttons .button:active {
  background-color: var(--jbmp-primary-color);
}

body .ui.primary.button:focus,
body .ui.primary.buttons body .button:focus {
  background-color: var(--jbmp-primary-color);
}
